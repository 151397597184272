.card-holder {
    position: relative;
    width: 420px;
    border-radius: 8px;
    border: 1px solid var(--light-lavender);
    background-color: var(--light-mist-blue);
    padding: 60px 60px 100px 60px;
}

.card {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
}
