.card-title {
  font-size: 24px;
  color: var(--midnight-blue);
  opacity: 0.65;
  font-weight: 400;
}

.card-title-1 {
  color: var(--transparent-black);
  opacity: 0.7;
  font-size: 26px;
}

.card-title-2 {
  font-size: 16px;
  color: var(--transparent-black);
  opacity: 0.7;
}

.card-logo {
  bottom: -62px;
}

.card-input {
  border-radius: 6px;
  border: 1px solid var(--light-lavender);
  background: var(--silver-blue);
}

.input {
  color: var(--steel-blue) !important;
  font-size: 14px;
}

.input::placeholder {
  color: var(--steel-blue) !important;
}

input:hover::placeholder {
  color: var(--royal-blue);
}

.card-input-error {
  border: 1px solid var(--light-red);
  border-radius: 6px;
  color: var(--light-red);
}

.card-input-error:hover {
  border-bottom: 1px solid var(--deep-red);
  color: var(--deep-red);
}

.card-button {
  border-radius: 6px;
  background-color: var(--silver-blue);
  color: var(--white);
}

.card-button__login {
  background: linear-gradient(
    180deg,
    var(--sky-blue) 0%,
    var(--royal-blue) 100%
  );
}

.card-button__login > p {
  color: var(--snow-white);
  font-size: 14px;
}

.card-button-login__effect:hover {
  background: var(--sky-blue);
}

.card-button__login:disabled {
  background: var(--royal-blue);
  opacity: 0.45 !important;
}

.card-button__bet,
.card-button__guest {
  @media screen and (max-width: 1200px) {
    padding: 10px 10px 10px 40px;
  }
}

.card-button__bet {
  background-color: var(--magenta);
}

.card-button__bet:hover {
  background: var(--bright-pink);
}

.card-button__guest {
  background-color: var(--blue-gray);
}

.card-button__guest:hover {
  background: var(--dark-blue);
}

.login-text {
  font-size: 16px;
  color: var(--gray);
}

.clear-icon {
  right: 12px;
  bottom: 57%;
}

.error-message {
  height: 21px;
  font-size: 12px;
  color: var(--light-red);
}

.icon-password {
  right: 30px;
  bottom: 33%;
}

.input-effect::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 5px;
  width: 0;
  height: 1px;
  background-color: var(--royal-blue);
  transition: width 0.5s ease-in-out !important;
}

.input-effect:hover::after {
  width: 97%;
}

.forgot-password {
  font-size: 12px;
  color: var(--black);
  opacity: 0.4;
}

.forgot-password:hover {
  font-size: 12px;
  color: var(--sky-blue);
  opacity: 1;
}

.read-only {
  background-color: transparent;
}
