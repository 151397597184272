.flex {
    &-display {
        display: flex;
    }

    &-display-inline {
        display: inline-flex;
    }

    //Flex direction
    &-direction-row {
        flex-direction: row;
    }

    &-direction-column {
        flex-direction: column;
    }

    &-direction-row-reverse {
        flex-direction: row-reverse;
    }

    //Flex align-items
    &-align-items-top {
        align-items: flex-start;
    }

    &-align-items-bottom {
        align-items: flex-end;
    }

    &-align-items-center {
        align-items: center;
    }

    &-align-items-baseline {
        align-items: baseline;
    }

    &-align-items-normal {
        align-items: normal;
    }

    //Flex align-self
    &-align-self-top {
        align-self: flex-start;
    }

    &-align-self-bottom {
        align-self: flex-end;
    }

    &-align-self-center {
        align-self: center;
    }

    &-align-self-baseline {
        align-self: baseline;
    }

    &-align-self-stretch {
        align-self: stretch;
    }

    //Justify Content
    &-justify-space-between {
        justify-content: space-between;
    }

    &-justify-content-end {
        justify-content: flex-end;
    }

    &-justify-space-evenly {
        justify-content: space-evenly;
    }

    &-justify-space-around {
        justify-content: space-around;
    }

    &-justify-end {
        justify-content: flex-end;
    }

    &-justify-center {
        justify-content: center;
    }

    &-justify-start {
        justify-content: flex-start;
    }

    //Wrap Content
    &-nowrap {
        flex-wrap: nowrap;
    }

    &-wrap {
        flex-wrap: wrap;
    }

    &-wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    // Flex Flow
    &-flow-col-wrap {
        flex-flow: column wrap;
    }

    &-flow-row-wrap {
        flex-flow: row wrap;
    }

    &-flow-row-nowrap {
        flex-flow: row nowrap;
    }
}
