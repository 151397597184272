.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.pointer-cursor {
    cursor: pointer;
}

.absolute-position {
    position: absolute;
}

.relative-position {
    position: relative;
}

.sticky-position {
    position: sticky;
}

.fixed-position {
    position: fixed;
}

.text {
    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }

    &-left {
        text-align: left;
    }
}

.bold {
    font-weight: bold;
}

.uppercase {
   text-transform: uppercase;
}








