.tooltip-container {
  display: inline-block;
}

.tooltip {
  min-width: 334px;
  top: -70px;
  left: 310px;
  border: 1px solid var(--light-lavender);
  background-color: var(--pale-silver);
  color: var(--steel-blue);
  border-radius: 6px;
  display: inline-block;
  white-space: nowrap;
  z-index: 9999;
}

.tooltip-triangle {
  bottom: 10%;
  left: -8px;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent var(--pale-silver) transparent transparent;
}
