@import 'src/assets/scss/magic';

.input-structure {
  width: 100%;
  position: relative;
  transition: 200ms background;
  z-index: 2;
  --font-size: #{rem(16)};
  @include mobile {
    --font-size: #{rem(14)};
  }
  input,
  select {
    width: 100%;
    height: var(--input-height);
    border-radius: inherit;
    padding: 0 calc(var(--input-height) / 2) 0 var(--input-height);
    color: sc(b);
    font-size: var(--font-size);
    font-weight: 400;
    box-shadow: 0 0 0 0 c(hero);
    transition: 200ms box-shadow, 200ms color;
    @include placeholder {
      font-size: var(--font-size);
      color: sc(b, 0.8);
      font-weight: 400;
      transition: 200ms color;
    }
    &.placeholder {
      font-size: var(--font-size);
      color: sc(b, 0.8);
      font-weight: 400;
    }
    &[readonly] {
      border: rem(1) dashed var(--light-gray);
    }
    &:not([readonly]):focus {
      box-shadow: 0 0 0 rem(2) c(hero);
      @include placeholder {
        color: sc(b, 0.5);
      }
      &.placeholder {
        color: sc(b, 0.5);
      }
    }
    .error-color & {
      &:not([readonly]):not(:focus) {
        @include over(hero, danger);
        transition-delay: 150ms;
        box-shadow: 0 0 0 rem(2) c(hero);
      }
    }
    &:-webkit-autofill {
      -webkit-text-fill-color: sc(b);
      -webkit-background-clip: text;
      &::first-line {
        font-size: var(--font-size);
        font-family: $f;
        font-weight: 400;
      }
    }
  }
  .icon {
    position: absolute;
    top: 0;
    width: var(--input-height);
    height: var(--input-height);
    transition: 300ms opacity, 300ms transform, 300ms color;
    &:not(.input-right-action) {
      left: 0;
    }
    &.input-right-action {
      right: 0;
      ~ input {
        padding-right: var(--input-height);
      }
      &.password-toggle {
        &:not(.active) {
          opacity: 0;
          transform: scale(0.7);
        }
        @include hover {
          color: c(hero);
        }
      }
    }
  }
}
.information-message {
  text-align: left;
  width: 100%;
  margin: rem(6) 0 0;
  font: 600 rem(12) / rem(16) $f;
  &.color-danger {
    color: c(danger);
  }
}
