@import 'core';

$c: (
    hero: #3f51b5,
    accent: #180335,
    primary: #014ed2,
    brand: #050505,
    danger: #e64e48,
    confirm: #4caf50,
    warning: #e6af57,
    theme: mix(#180335, #3f51b5),
    b: #ffffff,
);

@each $name, $color in $c {
    $c: map-merge($c, (#{$name + '-hover'}: set-lightness($color, 5)));
    $c: map-merge($c, (#{$name + '-lighten'}: lighten($color, 10)));
    $c: map-merge($c, (#{$name + '-darken'}: darken($color, 10)));
    $c: map-merge($c, (#{$name + '-sc'}: set-color($color)));
    $c: map-merge($c, (#{$name + '-rgb'}: #{red($color), green($color), blue($color)}));
    $c: map-merge($c, (#{$name + '-sc-rgb'}: #{red(set-color($color)), green(set-color($color)), blue(set-color($color))}));
}

$f: var(--font-family);
