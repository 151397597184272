.fp-text {
  font-size: 12px;
  color: var(--black-transparent-2);
}

.fp-login-link {
  display: inline-block;
  font-size: 12px;
  color: var(--royal-blue);
}
