@import 'magic';
@import 'reset';
@import 'global';
@import 'spacing';
@import 'flex';
:root {
  --font-family: Roboto, sans-serif;
  --light-lavender: #eaecfb;
  --light-mist-blue: rgba(252, 252, 254, 0.7);
  --midnight-blue: #041c2cd9;
  --dark-blue: #323942;
  --royal-blue: #5e5ee1;
  --gray: #7574a6;
  --sky-blue: #7e8ced;
  --silver-blue: #f8f9fc;
  --steel-blue: #4e5481;
  --light-red: #ff7875;
  --deep-red: #ff4d4f73;
  --transparent-white: #ffffffd9;
  --pale-silver: #fcfcfe;
  --forest-green: #228b22;
  --deep-yellow: #ffc107;
  --dark-red: #cc0000;
  --light-gray: #9d9db1;
  --dark-indigo: #1e1934;
  --snow-white: #fdfdff;
  --transparent-black: #000000b2;
  --black: #000000;
  --white: #ffffff;
  --magenta: #bb2374;
  --blue-gray: #242930;
  --bright-pink: #CB237C;
  --black-transparent-2: #00000066;
}
html {
  font: 10px / 1.5 $f;
  -webkit-text-size-adjust: 100%;
  background: c(page-background);
  color: sc(b);
  user-select: none;
  height: 100%;
  @at-root {
    &,
    body,
    #root {
      display: flex;
      width: 100%;
      min-height: 100%;
      max-width: 100vw;
    }
  }
}
body {
  font-size: rem(14);
}
.guide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #dfe3f9;
  background-image: url('../images/bubble1.svg'), url('../images/bubble2.svg'),
    url('../images/bubble3.svg'), url('../images/bubble4.svg'),
    url('../images/bubble5.svg');
  background-position: 100% 100%, 72% 0, 30% 100%, 20% 55%, 0 21%;
  background-repeat: no-repeat;
}
@keyframes reveal {
  to {
    opacity: 1;
  }
}
