$spacing-data : (
    "--0": 0,
    "--2": 0.125rem,
    "--4": 0.25rem,
    "--8": 0.5rem,
    "--12": 0.75rem,
    "--16": 1rem,
    "--24": 1.5rem,
    "--28": 1.75rem,
    "--32": 2rem,
    "--40": 2.5rem,
    "--48": 3rem,
    "--56": 3.5rem,
    "--64": 4rem,
    "--80": 5rem,
    "--96": 6rem,
    "--112": 7rem,
);

//pt - padding-top
//pb - padding-bottom
//pl - padding-left
//pr - padding-right
//mt - margin-top
//mb - margin-bottom
//ml - margin-left
//mr - margin-right
//mh - margin-left margin-right
//mv - padding-top margin-bottom
//ph - padding-left padding-right
//pv - padding-top padding-bottom
//m -  margin
//p -  padding
//usage example - <div className="mt--80 pb--32">...</div>

@each $key, $value in $spacing-data {
    .pt#{$key}
    {
        padding-top: $value !important
    };
    .pb#{$key} {
        padding-bottom: $value !important
    };
    .pl#{$key} {
        padding-left: $value !important
    };
    .pr#{$key} {
        padding-right: $value !important
    };
    .mt#{$key} {
        margin-top: $value !important
    };
    .mb#{$key} {
        margin-bottom: $value !important
    };
    .ml#{$key} {
        margin-left: $value !important
    };
    .mr#{$key} {
        margin-right: $value !important
    };
    .p#{$key} {
        padding: $value !important
    };
    .m#{$key} {
        margin: $value !important
    };
    .ph#{$key} {
        padding-left: $value !important;
        padding-right: $value !important
    };
    .mh#{$key} {
        margin-left: $value !important;
        margin-right: $value !important
    };
    .pv#{$key} {
        padding-top: $value !important;
        padding-bottom: $value !important
    };
    .mv#{$key} {
        margin-top: $value !important;
        margin-bottom: $value !important
    };
}
